<template>
  <nav
    class="flix-navbar flix-navbar-expand-lg flix-navbar-light flix-navbar-default"
  >
    <div class="flix-container-fluid flix-html-html flix-text-center">
      <div class="flix-navbar-header flix-html-html">
        <button
          type="button"
          @click="toggleMenu = !toggleMenu"
          :class="{ 'flix-collapsed': toggleMenu }"
          class="flix-navbar-toggle"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false"
        >
          <span class="sr-only"
            ><flixIcon :id="'menu-hamburger'" /> Navigation</span
          >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a
          :href="
            'https://bookingflix.' +
            ($i18n.locale === 'en' ? 'net' : $i18n.locale)
          "
          class="flix-navbar-brand flix-html-a"
          ><logo class="logo"
        /></a>
      </div>
      <div
        :class="{ 'flix-collapse': toggleMenu }"
        class="flix-navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul class="flix-nav flix-navbar-nav">
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'home'}}">{{ $tc("message.home", 1) }}</router-link></li> -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'quickstart'}}">{{ $tc("message.businessCalendar", 2) }}</router-link></li> -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'private-event'}}">{{ $tc("message.privateCalendar", 2) }}</router-link></li> -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'tools'}}">{{ $tc("message.tools", 1) }}</router-link></li> -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'price'}}">{{ $tc("message.function", 2) }} &amp; {{ $tc("message.price", 2) }}</router-link></li>  -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'support'}}">{{ $tc("message.supportAndBlog", 2) }}</router-link></li> -->
          <!-- <li class="flix-html-li"><router-link class="flix-html-a" :to="{name: 'frontend', params: {page: 'best-practice'}}">{{ $tc("message.bestPractice", 2) }}</router-link></li> -->
          <li class="flix-html-li">
            <a
              class="flix-html-a"
              :href="
                'https://bookingflix.' +
                ($i18n.locale === 'en' ? 'net' : $i18n.locale)
              "
              ><span class="flix-text-default"
                ><flixIcon :id="'home'" />
                {{ $tc('message.toHomepage', 1) }}</span
              ></a
            >
          </li>
          <li class="flix-html-li">
            <router-link
              class="flix-html-a"
              :to="{
                name: 'signInTranslation',
                params: { lang: $i18n.locale }
              }"
              ><span class="flix-text-danger"
                ><flixIcon :id="'unlock'" />
                {{ $tc('message.signIn', 1) }}</span
              ></router-link
            >
          </li>
        </ul>
        <switchLanguage />
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  components: {
    switchLanguage() {
      return import('@/components/frontend/navigation/switchLanguage')
    }
  },
  data() {
    return {
      toggleMenu: true,
      searchResults: this.getSearchResults()
    }
  },
  methods: {
    getSearchResults() {
      if (
        typeof this.$store.getters.search !== 'object' ||
        !Object.keys(this.$store.getters.search).length
      ) {
        return false
      }
      var r = 0
      Object.values(this.$store.getters.search.results).forEach(function (v) {
        r = r + v.length
      })
      return r
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/assets/style/sass/stylesheet/flix_color_style.scss'
.flix-navbar-brand
  display: inline
  height: inherit
  margin-right: 0
  width: 200px
  .logo
    height: 40px
    margin-bottom: 20px
    display: block
@media(max-width: 500px)
  .flix-navbar-brand
    display: block
    height: inherit
    width: 100%
    .logo
      display: block
      width: 100%
      clear: both
.router-link-exact-active
  border-bottom: 2px solid $brand-primary
  &.flix-navbar-brand
    border-bottom: 0
</style>
